import { render, staticRenderFns } from "./GtcViewHU.vue?vue&type=template&id=48c6f79a&scoped=true"
import script from "./GtcViewHU.vue?vue&type=script&lang=js"
export * from "./GtcViewHU.vue?vue&type=script&lang=js"
import style0 from "./GtcViewHU.vue?vue&type=style&index=0&id=48c6f79a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c6f79a",
  null
  
)

export default component.exports