<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="arrow-left"/>
			{{ $t('wizard.prev') }}
		</a>
		<h1>&Aacute;ltal&aacute;nos szerződ&eacute;si felt&eacute;telek</h1>

		<p>A <strong>PilotNet Kft.</strong> (a tov&aacute;bbiakban:<strong>&bdquo;Szolg&aacute;ltat&oacute;&rdquo;</strong>) <strong>PilotNet</strong> elnevez&eacute;sű okostelefon Alkalmaz&aacute;sban (a tov&aacute;bbiakban: <strong>&bdquo;Alkalmaz&aacute;s&rdquo;</strong>) vagy a b&ouml;ng&eacute;szőből el&eacute;rhető rep&uuml;l&eacute;s tervez&eacute;s seg&iacute;t&eacute;s&eacute;t c&eacute;lz&oacute; fel&uuml;leten regisztr&aacute;l&oacute; felhaszn&aacute;l&oacute; (a tov&aacute;bbiakban: <strong>&bdquo;Felhaszn&aacute;l&oacute;&rdquo;</strong>) valamint a b&ouml;ng&eacute;szőből el&eacute;rhető t&eacute;rk&eacute;p inform&aacute;ci&oacute;it (a tov&aacute;bbiakban: <strong>&bdquo;Eseti Felhaszn&aacute;l&oacute;&rdquo;</strong>) a jelen &Aacute;ltal&aacute;nos Szerződ&eacute;si Felt&eacute;telekben foglaltakat elfogadja, mag&aacute;ra n&eacute;zve k&ouml;telezőnek ismeri el.</p>

		<p>Amennyiben a Felhaszn&aacute;l&oacute; nem j&aacute;rul hozz&aacute; a jelen &Aacute;ltal&aacute;nos Szerződ&eacute;si Felt&eacute;telek (a tov&aacute;bbiakban:<strong>&bdquo;&Aacute;SZF&rdquo;</strong>) alkalmaz&aacute;s&aacute;hoz, &uacute;gy az Alkalmaz&aacute;s, a b&ouml;ng&eacute;szőből el&eacute;rhető t&eacute;rk&eacute;p inform&aacute;ci&oacute;k &eacute;s az azokhoz kapcsol&oacute;d&oacute; rep&uuml;l&eacute;s tervez&eacute;st seg&iacute;tő szolg&aacute;ltat&aacute;sok nem haszn&aacute;lhat&oacute;k &eacute;s nem vehetők ig&eacute;nybe.</p>

		<h1>Nyilatkozat:</h1>

		<p>Az Alkalmaz&aacute;s haszn&aacute;lata előtt a Felhaszn&aacute;l&oacute; k&ouml;teles elolvasni az al&aacute;bbi nyilatkozatot. Az Alkalmaz&aacute;s haszn&aacute;lat&aacute;val a Felhaszn&aacute;l&oacute; elfogadja az al&aacute;bbiakat:</p>

		<p><em>&ldquo;A PilotNet rendszer egy hat&oacute;s&aacute;gi tan&uacute;s&iacute;tv&aacute;nnyal nem rendelkező, rep&uuml;l&eacute;st seg&iacute;tő szoftver. A megjelen&iacute;tett adatok pil&oacute;t&aacute;kt&oacute;l, f&ouml;ldi &aacute;llom&aacute;sok &uuml;zemeltetőitől; valamint az internetről el&eacute;rhető egy&eacute;b szolg&aacute;ltat&oacute;kt&oacute;l j&ouml;vő, nem struktur&aacute;lt inform&aacute;ci&oacute;halmazb&oacute;l sz&aacute;rmaznak. A PilotNet rendszer semmilyen m&oacute;don nem tudja garant&aacute;lni sem az adatok pontoss&aacute;g&aacute;t &eacute;s teljess&eacute;g&eacute;t, sem az &aacute;lland&oacute; adat&aacute;tvitel biztos&iacute;t&aacute;s&aacute;t a Felhaszn&aacute;l&oacute; sz&aacute;m&aacute;ra a Szolg&aacute;ltat&aacute;s haszn&aacute;lata k&ouml;zben.</em></p>

		<p><em>A Szolg&aacute;ltat&aacute;s c&eacute;lja, hogy kihangs&uacute;lyozza a rep&uuml;lőg&eacute;pvezető k&ouml;teless&eacute;g&eacute;t az elk&uuml;l&ouml;n&iacute;t&eacute;s biztos&iacute;t&aacute;s&aacute;ra a saj&aacute;t l&eacute;gij&aacute;rműve &eacute;s t&ouml;bbi l&eacute;gij&aacute;rmű k&ouml;z&ouml;tt rep&uuml;l&eacute;s k&ouml;zben. A k&ouml;zelben l&eacute;vő l&eacute;gij&aacute;rművek vizu&aacute;lis azonos&iacute;t&aacute;sa k&ouml;telező, &eacute;s a l&eacute;gij&aacute;rmű parancsnok&aacute;nak a k&ouml;teless&eacute;ge. IMC meteorol&oacute;giai k&ouml;r&uuml;lm&eacute;nyek k&ouml;z&ouml;tt az Alkalmaz&aacute;s haszn&aacute;lata tilos.</em></p>

		<p><em>A PilotNet rendszer semmilyen m&oacute;don nem tehető felelőss&eacute; az Applik&aacute;ci&oacute; haszn&aacute;lat&aacute;b&oacute;l eredő esetleges b&aacute;rmilyen k&aacute;r&eacute;rt.</em></p>

		<p><em>A PilotNet rendszerből sz&aacute;rmaz&oacute; inform&aacute;ci&oacute;k semmilyen esetben sem b&iacute;r&aacute;lj&aacute;k fel&uuml;l a hivatalos csatorn&aacute;kon &eacute;rkező inform&aacute;ci&oacute;kat.</em></p>

		<p><em>A PilotNet rendszerből sz&aacute;rmaz&oacute; inform&aacute;ci&oacute;k egy r&eacute;sze az Alkalmaz&aacute;s tov&aacute;bbi Felhaszn&aacute;l&oacute;it&oacute;l, valamint harmadik felektől beszerzett inform&aacute;ci&oacute;k feldolgoz&aacute;s&aacute;b&oacute;l sz&aacute;rmazik. Ezek az inform&aacute;ci&oacute;k alapvetően ingadoz&oacute;ak, pontatlanok, hi&aacute;nyosak &eacute;s elavultak lehetnek. A PilotNet rendszer nem v&aacute;llal semmilyen garanci&aacute;t ezen adatok hiteless&eacute;g&eacute;re &eacute;s megb&iacute;zhat&oacute;s&aacute;g&aacute;ra vonatkoz&oacute;an.</em></p>

		<p><em>A PilotNet rendszer műk&ouml;d&eacute;s&eacute;hez sz&uuml;ks&eacute;ges poz&iacute;ci&oacute; inform&aacute;ci&oacute;k begyűjt&eacute;se. Bizonyos funkci&oacute;k felhaszn&aacute;lj&aacute;k a r&eacute;szletes hely- &eacute;s &uacute;tvonalinform&aacute;ci&oacute;kat, amiket a Felhaszn&aacute;l&oacute; azon mobil eszk&ouml;ze szolg&aacute;ltat, amelyre az Alkalmaz&aacute;s telep&iacute;t&eacute;sre ker&uuml;lt.</em></p>

		<p><em>A PilotNet rendszer bizonyos funkci&oacute;inak műk&ouml;d&eacute;s&eacute;hez internet kapcsolat megl&eacute;te sz&uuml;ks&eacute;ges. Az ennek nyom&aacute;n keletkező k&ouml;lts&eacute;gek (adatforgalom alap&uacute; k&ouml;lts&eacute;gek) kiz&aacute;r&oacute;lag a Felhaszn&aacute;l&oacute;t terhelik. Az Alkalmaz&aacute;s &aacute;ltal megjelen&iacute;tett &eacute;s elk&uuml;ld&ouml;tt inform&aacute;ci&oacute;k val&oacute;s idejűs&eacute;g&eacute;nek garant&aacute;l&aacute;s&aacute;hoz online kapcsolat sz&uuml;ks&eacute;ges a Felhaszn&aacute;l&oacute; Alkalmaz&aacute;st futtat&oacute; eszk&ouml;ze &eacute;s az internet k&ouml;z&ouml;tt (p&eacute;ld&aacute;ul Wi-Fi, 3G, 4G, 5G kapcsolat megl&eacute;te). Az online kapcsolat k&ouml;lts&eacute;geivel kapcsolatban a Felhaszn&aacute;l&oacute; &eacute;s internetszolg&aacute;ltat&oacute;ja k&ouml;z&ouml;tti szerződ&eacute;s rendelkezik.</em></p>

		<p><em>A PilotNet Alkalmaz&aacute;s haszn&aacute;lata regisztr&aacute;ci&oacute;hoz k&ouml;t&ouml;tt.&rdquo;</em></p>

		<h2>1. Szolg&aacute;ltat&oacute; adatai</h2>

		<p>A <a href="https://pilotnet.hu" target="_blank">https://pilotnet.hu</a> Weboldal, ahol a b&ouml;ng&eacute;szőből el&eacute;rhető rep&uuml;l&eacute;s tervez&eacute;s elv&eacute;gezhető, valamint az Alkalmaz&aacute;s tulajdonosa a Szolg&aacute;ltat&oacute;, &uuml;zemeltetője a <strong>Ready 2 Grow Kft.</strong></p>

		<p>N&eacute;v:PilotNet Kft.<br />
			Sz&eacute;khely: 2100 G&ouml;d&ouml;llő, Esze Tam&aacute;s utca 3.<br />
			C&eacute;gjegyz&eacute;ksz&aacute;m: 113-09-227621<br />
			Ad&oacute;sz&aacute;m: 32298181-2-13</p>

		<p>N&eacute;v: Ready 2 Grow Kft.<br />
			Sz&eacute;khely: 2100 G&ouml;d&ouml;llő, Remsey krt. 8.<br />
			C&eacute;gjegyz&eacute;ksz&aacute;m: 13-09-177610<br />
			Ad&oacute;sz&aacute;m: 25397130-2-13</p>

		<p>Telefonos &Uuml;gyf&eacute;lszolg&aacute;lat el&eacute;rhetős&eacute;ge: <a href="tel:+36203678407">+36 20 367 84 07</a><br />
			Az Ready 2 Grow Kft. honlapj&aacute;nak c&iacute;me: <a href="https://r2g.hu" target="_blank">https://r2g.hu</a><br />
			Az Ready 2 Grow Kft. e-mail c&iacute;me: <a href="mailto:info@r2g.hu">info@r2g.hu</a></p>

		<h2>2. Regisztr&aacute;ci&oacute; a szolg&aacute;ltat&aacute;s ig&eacute;nybev&eacute;tel&eacute;re</h2>

		<h3>a) A Szolg&aacute;ltat&oacute; Alkalmaz&aacute;sa</h3>

		<p>A Szolg&aacute;ltat&oacute; &aacute;ltal biztos&iacute;tott szolg&aacute;ltat&aacute;sok el&eacute;rhetők iOS &eacute;s Android oper&aacute;ci&oacute;s rendszerekre fejlesztett PilotNet applik&aacute;ci&oacute;j&aacute;n (a haszn&aacute;lata regisztr&aacute;ci&oacute;hoz k&ouml;t&ouml;tt) valamint a PilotNet weboldal&aacute;n (<a href="https://pilotnet.hu" target="_blank">https://pilotnet.hu</a>) kereszt&uuml;l egyar&aacute;nt.</p>

		<p>Az Alkalmaz&aacute;st b&aacute;rki let&ouml;ltheti, abban b&aacute;rki regisztr&aacute;lhat.</p>

		<p>Az Alkalmaz&aacute;s haszn&aacute;lat&aacute;nak megkezd&eacute;s&eacute;nek felt&eacute;tele, hogy a Felhaszn&aacute;l&oacute; az Alkalmaz&aacute;sban szem&eacute;lyes felhaszn&aacute;l&oacute;i fi&oacute;kot hozzon l&eacute;tre,az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;t &eacute;s az &aacute;ltal&aacute;nos szerződ&eacute;si felt&eacute;teleket &eacute;s a nyilatkozatot elolvassa &eacute;s elfogadja.</p>

		<p>A regisztr&aacute;ci&oacute; d&iacute;jmentes.</p>

		<p>A regisztr&aacute;ci&oacute;megerős&iacute;t&eacute;s&eacute;re e-mailben ker&uuml;l sor.</p>

		<p>A Szolg&aacute;ltat&oacute; t&ouml;rekszik arra, hogy folyamatos &eacute;s fennakad&aacute;s n&eacute;lk&uuml;li szolg&aacute;ltat&aacute;st ny&uacute;jtson a Felhaszn&aacute;l&oacute; r&eacute;sz&eacute;re, de nem tudja garant&aacute;lni, hogy a Szolg&aacute;ltat&aacute;s minden esetben el&eacute;rhető, folyamatos, időszerű vagy hib&aacute;tlan lesz.</p>

		<h3>b) A Felhaszn&aacute;l&oacute; &eacute;s a Szolg&aacute;ltat&oacute; k&ouml;z&ouml;tt a szerződ&eacute;s</h3>

		<ul>
			<li>a Felhaszn&aacute;l&oacute;nak a Szolg&aacute;ltat&oacute; Alkalmaz&aacute;s&aacute;nak mobiltelefonra t&ouml;rt&eacute;nő let&ouml;lt&eacute;s&eacute;vel &eacute;s az Alkalmaz&aacute;sban t&ouml;rt&eacute;nt sikeres regisztr&aacute;ci&oacute;j&aacute;val j&ouml;n l&eacute;tre, vagy</li>
			<li>a Weboldalon kezdem&eacute;nyezett szint&eacute;n regisztr&aacute;ci&oacute;hoz k&ouml;t&ouml;tt funkci&oacute;k haszn&aacute;lat&aacute;val j&ouml;n l&eacute;tre.</li>
		</ul>

		<h3>c) A Szerződ&eacute;sk&ouml;t&eacute;s technikai l&eacute;p&eacute;sei</h3>

		<h4>Az Alkalmaz&aacute;sban:</h4>

		<ul>
			<li>az Alkalmaz&aacute;s let&ouml;lt&eacute;se App Store-b&oacute;l vagy Google Play &aacute;ruh&aacute;zb&oacute;l</li>
			<li>az Alkalmaz&aacute;s telep&iacute;t&eacute;se</li>
			<li>a fők&eacute;pernyőn a bel&eacute;p&eacute;s gombra kattint&aacute;s</li>
			<li>email c&iacute;m, jelsz&oacute; megad&aacute;sa &eacute;s megerős&iacute;t&eacute;se. A jelsz&oacute;nak legal&aacute;bb 8 karakterből kell &aacute;llnia, tartalmaznia kell kis- &eacute;s nagybetűt valamint sz&aacute;mot</li>
			<li>az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;, a nyilatkozat &eacute;s az &aacute;ltal&aacute;nos szerződ&eacute;si felt&eacute;telek let&ouml;lt&eacute;se, elolvas&aacute;sa;</li>
			<li>az &aacute;ltal&aacute;nos szerződ&eacute;si felt&eacute;telek, a nyilatkozat &eacute;s az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute; mellett l&eacute;vő gomb &aacute;tkapcsol&aacute;sa azok meg&eacute;rt&eacute;s&eacute;nek &eacute;s tudom&aacute;sul v&eacute;tel&eacute;nek megerős&iacute;t&eacute;sek&eacute;nt;</li>
		</ul>

		<p>&nbsp;</p>

		<h4>A Weblapon:</h4>

		<ul>
			<li>a weboldal megnyit&aacute;sa</li>
			<li>a fők&eacute;pernyőn a bel&eacute;p&eacute;s gombra kattint&aacute;s</li>
			<li>email c&iacute;m, jelsz&oacute; megad&aacute;sa &eacute;s megerős&iacute;t&eacute;se. A jelsz&oacute;nak legal&aacute;bb 8 karakterből kell &aacute;llnia, tartalmaznia kell kis- &eacute;s nagybetűt valamint sz&aacute;mot</li>
			<li>az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;, a nyilatkozat &eacute;s az &aacute;ltal&aacute;nos szerződ&eacute;si felt&eacute;telek let&ouml;lt&eacute;se, elolvas&aacute;sa;</li>
			<li>az &aacute;ltal&aacute;nos szerződ&eacute;si felt&eacute;telek, a nyilatkozat &eacute;s az adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute; mellett l&eacute;vő gomb &aacute;tkapcsol&aacute;sa azok meg&eacute;rt&eacute;s&eacute;nek &eacute;s tudom&aacute;sul v&eacute;tel&eacute;nek megerős&iacute;t&eacute;sek&eacute;nt;</li>
		</ul>

		<p>A Felhaszn&aacute;l&oacute; az online regisztr&aacute;ci&oacute;val elfogadja, hogy a Szolg&aacute;ltat&oacute; online m&oacute;don biztos&iacute;tja a jelen &Aacute;SZF-ben meghat&aacute;rozott szolg&aacute;ltat&aacute;sokat &eacute;s azokra vonatkoz&oacute; b&aacute;rmely inform&aacute;ci&oacute;t a Szolg&aacute;ltat&oacute; a Felhaszn&aacute;l&oacute; &aacute;ltal megadott regisztr&aacute;ci&oacute;s e-mail c&iacute;mre tov&aacute;bb&iacute;tja. Amennyiben a Szolg&aacute;ltat&oacute; az &Aacute;SZF m&oacute;dos&iacute;t&aacute;s&aacute;r&oacute;l hat&aacute;roz, a m&oacute;dos&iacute;t&aacute;s tartalm&aacute;t a Felhaszn&aacute;l&oacute; regisztr&aacute;lt email c&iacute;m&eacute;re k&uuml;ldi meg vagy az Alkalmaz&aacute;sban jelzi az &Aacute;SZF m&oacute;dos&iacute;t&aacute;s&aacute;t, a hat&aacute;lyos verzi&oacute; elhelyez&eacute;s&eacute;vel. A Felhaszn&aacute;l&oacute; a rendszer haszn&aacute;lat&aacute;val &Aacute;SZF m&oacute;dos&iacute;t&aacute;sait is elfogadja.</p>

		<p>A regisztr&aacute;ci&oacute; folyam&aacute;n a Felhaszn&aacute;l&oacute; k&ouml;teles a hozz&aacute; k&ouml;thető, val&oacute;s adatokat megadni. A Szolg&aacute;ltat&oacute;, amennyiben tudom&aacute;s&aacute;ra jut, hogy a Felhaszn&aacute;l&oacute; &aacute;ltal megadott adatok k&ouml;re nem val&oacute;s elemeket (is) tartalmaz, jogosult a hozz&aacute;f&eacute;r&eacute;st r&eacute;szlegesen, vagy teljes eg&eacute;sz&eacute;ben korl&aacute;tozni, illetve az Alkalmaz&aacute;s felhaszn&aacute;l&aacute;s&aacute;b&oacute;l kiz&aacute;rni.</p>

		<p>A Szolg&aacute;ltat&oacute; fenntartja mag&aacute;nak a jogot, hogy a regisztr&aacute;ci&oacute;t k&uuml;l&ouml;n indokol&aacute;s n&eacute;lk&uuml;l visszautas&iacute;tsa, vagy b&aacute;rmikor &aacute;tmenetileg, vagy v&eacute;glegesen visszavonja, k&uuml;l&ouml;n&ouml;sen az al&aacute;bbi esetekben:</p>

		<ul>
			<li>regisztr&aacute;ci&oacute; sor&aacute;n val&oacute;tlan vagy hi&aacute;nyos adatok megad&aacute;sa eset&eacute;n.</li>
			<li>kor&aacute;bban regisztr&aacute;lt Felhaszn&aacute;l&oacute;, akinek szerződ&eacute;s&eacute;t a Szolg&aacute;ltat&oacute; szerződ&eacute;sszeg&eacute;s miatt kor&aacute;bban felmondta.</li>
		</ul>

		<p>A Felhaszn&aacute;l&oacute; k&ouml;teles gondoskodni az &aacute;ltala regisztr&aacute;lt online el&eacute;rhetős&eacute;g (e-mail c&iacute;m) műk&ouml;d&eacute;s&eacute;ről (fenntart&aacute;s&aacute;r&oacute;l). Helytelen vagy műk&ouml;d&eacute;sk&eacute;ptelen online el&eacute;rhetős&eacute;g (e-mail c&iacute;m) megad&aacute;s&aacute;b&oacute;l eredő b&aacute;rmilyen k&aacute;r&eacute;rt a Szolg&aacute;ltat&oacute; nem v&aacute;llal felelőss&eacute;get, a Felhaszn&aacute;l&oacute; a Szolg&aacute;ltat&oacute;val szemben fenn&aacute;ll&oacute; k&ouml;telezetts&eacute;gek&eacute;rt k&ouml;teles helyt&aacute;llni.</p>

		<p>A Szolg&aacute;ltat&oacute; t&aacute;j&eacute;koztatja &eacute;s felh&iacute;vja a Felhaszn&aacute;l&oacute; figyelm&eacute;t arra, hogy rendszeresen m&oacute;dos&iacute;tsa jelszav&aacute;t, &eacute;s azt senkinek ne adja &aacute;t, m&aacute;sok &aacute;ltal hozz&aacute;f&eacute;rhető helyen ne t&aacute;rolja. A jelsz&oacute; biztons&aacute;gos megőrz&eacute;se, tov&aacute;bb&aacute; az aktu&aacute;lis, a Felhaszn&aacute;l&oacute; &eacute;rtes&iacute;t&eacute;s&eacute;re alkalmas online el&eacute;rhetős&eacute;g (e-mail c&iacute;m) megad&aacute;sa az Alkalmaz&aacute;s haszn&aacute;lata eset&eacute;n is k&ouml;telező, &eacute;s a Felhaszn&aacute;l&oacute; &eacute;rdekk&ouml;r&eacute;be tartoz&oacute; esem&eacute;ny, melyek elmulaszt&aacute;s&aacute;b&oacute;l fakad&oacute; k&aacute;rok&eacute;rt a Szolg&aacute;ltat&oacute; nem felel, &eacute;s melynek elmulaszt&aacute;sa nem mentes&iacute;ti a Felhaszn&aacute;l&oacute;t a Szolg&aacute;ltat&oacute; fel&eacute; keletkezett k&ouml;telezetts&eacute;gei teljes&iacute;t&eacute;se al&oacute;l.</p>

		<h3>d) Elfelejtett jelsz&oacute;</h3>

		<p>A Felhaszn&aacute;l&oacute; az Alkalmaz&aacute;sba t&ouml;rt&eacute;nő bejelentkez&eacute;s&eacute;t megelőzően, az &bdquo;Elfelejtett jelsz&oacute;&rdquo; funkci&oacute; seg&iacute;ts&eacute;g&eacute;vel &uacute;j jelsz&oacute;t ig&eacute;nyelhet. A rendszer automatikus &uuml;zenetet k&uuml;ld a regisztr&aacute;ci&oacute;kor megadott e-mail c&iacute;mre, mely tartalmaz egy Weboldalra, fel&uuml;letre ir&aacute;ny&iacute;t&oacute; hivatkoz&aacute;st (linket). Erre a linkre kattintva tud a Felhaszn&aacute;l&oacute; &uacute;j jelsz&oacute;t megadni. A sikertelen jelsz&oacute;v&aacute;ltoztat&aacute;s eset&eacute;n a Felhaszn&aacute;l&oacute; a Szolg&aacute;ltat&oacute; &Uuml;gyf&eacute;lszolg&aacute;lat&aacute;hoz,<a href="mailto:info@r2g.hu">info@r2g.hu</a>email c&iacute;men fordulhat seg&iacute;ts&eacute;g&eacute;rt.</p>

		<h3>e) Profiladatok</h3>

		<p>A Felhaszn&aacute;l&oacute; a Profil men&uuml;pontban tudja megv&aacute;ltoztatni a telefonsz&aacute;m&aacute;t az email c&iacute;m&eacute;t valamint a bel&eacute;p&eacute;shez haszn&aacute;lt jelszav&aacute;t. A Profil men&uuml;pontban van lehetős&eacute;g a Profil t&ouml;rl&eacute;s&eacute;re is. A profil t&ouml;rl&eacute;s&eacute;vel felhaszn&aacute;l&oacute;i fi&oacute;k megszűnik, &eacute;s minden szem&eacute;lyes adat visszavonhatatlanul t&ouml;rl&eacute;sre ker&uuml;l. Amennyiben a Felhaszn&aacute;l&oacute; m&aacute;r ind&iacute;tott rep&uuml;l&eacute;st az alkalmaz&aacute;sban, &uacute;gy ezen Felhaszn&aacute;l&oacute; rep&uuml;l&eacute;s&eacute;nek adatai tov&aacute;bbra is t&aacute;rol&aacute;sra ker&uuml;lnek a adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;ban elő&iacute;rt hat&aacute;ridőig. A t&ouml;rl&eacute;s ut&aacute;n a t&ouml;r&ouml;lt felhaszn&aacute;l&oacute;i fi&oacute;kkal m&aacute;r nem lehet bejelentkezni.</p>

		<h3>f) Jelsz&oacute;m&oacute;dos&iacute;t&aacute;s</h3>

		<p>Amennyiben a Felhaszn&aacute;l&oacute; m&oacute;dos&iacute;tani k&iacute;v&aacute;nja a jelszav&aacute;t, &uacute;j jelsz&oacute; megad&aacute;s&aacute;ra az Alkalmaz&aacute;sban vagy a weboldalon t&ouml;rt&eacute;nt bel&eacute;p&eacute;s ut&aacute;n van lehetős&eacute;ge. A sikeres jelsz&oacute;v&aacute;ltoztat&aacute;sr&oacute;l a rendszer automatikus visszajelz&eacute;st ad. A sikertelen jelsz&oacute;v&aacute;ltoztat&aacute;s eset&eacute;n a Felhaszn&aacute;l&oacute; a Szolg&aacute;ltat&oacute; &Uuml;gyf&eacute;lszolg&aacute;lat&aacute;hoz fordulhat seg&iacute;ts&eacute;g&eacute;rt.</p>

		<h2>3. Felhaszn&aacute;l&oacute; k&ouml;teless&eacute;gei</h2>

		<p>Rep&uuml;l&eacute;s k&ouml;zben a rep&uuml;l&eacute;s szab&aacute;lyainak megfelel&eacute;s a Felhaszn&aacute;l&oacute; feladata. Ennek elmulaszt&aacute;s&aacute;b&oacute;l eredően Szolg&aacute;ltat&oacute; nem tartozik felelőss&eacute;ggel.</p>

		<p>A Szolg&aacute;ltat&aacute;s informatikai tartalm&aacute;t szerzői jog, v&eacute;djegy &eacute;s/vagy egy&eacute;b szellemi tulajdonra vonatkoz&oacute; oltalmi jogok v&eacute;dik.</p>

		<p>A Szolg&aacute;ltat&aacute;s &eacute;s a Szolg&aacute;ltat&aacute;s funkci&oacute;i nem &eacute;rt&eacute;kes&iacute;thetőek tov&aacute;bb a Felhaszn&aacute;l&oacute; &aacute;ltal a Szolg&aacute;ltat&oacute; enged&eacute;lye n&eacute;lk&uuml;l.</p>

		<p>Felhaszn&aacute;l&oacute; nem jogosult:</p>

		<ul>
			<li>a Szolg&aacute;ltat&aacute;s vagy annak b&aacute;rmely funkci&oacute;j&aacute;nak kereskedelmi c&eacute;llal t&ouml;rt&eacute;nő tov&aacute;bb &eacute;rt&eacute;kes&iacute;t&eacute;s&eacute;re</li>
			<li>A Felhaszn&aacute;l&oacute; nem jogosult az Alkalmaz&aacute;sban &aacute;tdolgoz&aacute;st, m&oacute;dos&iacute;t&aacute;st v&eacute;grehajtani, &eacute;s nem jogosult azt m&aacute;solni, t&ouml;bbsz&ouml;r&ouml;zni, &eacute;rt&eacute;kes&iacute;teni, b&aacute;rmely jogc&iacute;men &aacute;truh&aacute;zni, k&oacute;djait visszafejteni.</li>
			<li>Szolg&aacute;ltat&aacute;s &aacute;ltal biztos&iacute;tott Tartalmat b&aacute;rmely m&aacute;s webhelyen vagy h&aacute;l&oacute;zati sz&aacute;m&iacute;t&oacute;g&eacute;pes k&ouml;rnyezetben b&aacute;rmilyen c&eacute;lra felhaszn&aacute;lni, illetve a Tartalmat a Szolg&aacute;ltat&oacute; előzetes &iacute;r&aacute;sbeli hozz&aacute;j&aacute;rul&aacute;sa n&eacute;lk&uuml;l reproduk&aacute;lni vagy m&aacute;solni.</li>
			<li>az Applik&aacute;ci&oacute; &eacute;s/vagy a Webhely b&aacute;rmely r&eacute;sz&eacute;nek m&aacute;sol&aacute;sa vagy t&uuml;kr&ouml;z&eacute;se a Szolg&aacute;ltat&oacute; előzetes &iacute;r&aacute;sos enged&eacute;lye n&eacute;lk&uuml;l;</li>
			<li>adatb&aacute;zis l&eacute;trehoz&aacute;s&aacute;ra a Szolg&aacute;ltat&aacute;sb&oacute;l sz&aacute;rmaz&oacute; Tartalom eg&eacute;sz&eacute;nek vagy egy r&eacute;sz&eacute;nek szisztematikus let&ouml;lt&eacute;s&eacute;vel &eacute;s t&aacute;rol&aacute;s&aacute;val;</li>
			<li>az Applik&aacute;ci&oacute;b&oacute;l &eacute;s/vagy a Webhelyről gener&aacute;lt adatok tov&aacute;bb&iacute;t&aacute;sa a Szolg&aacute;ltat&oacute; előzetes &iacute;r&aacute;sbeli hozz&aacute;j&aacute;rul&aacute;sa n&eacute;lk&uuml;l;</li>
		</ul>

		<p>Az Alkalmaz&aacute;sra, mint sz&aacute;m&iacute;t&oacute;g&eacute;pi programalkot&aacute;sban megnyilv&aacute;nul&oacute; szerzői műre vonatkoz&oacute; valamennyi szem&eacute;lyhez fűződő &eacute;s vagyoni szerzői jog jogosultja a Szolg&aacute;ltat&oacute;. A Szolg&aacute;ltat&oacute; a jelen &Aacute;SZF elfogad&aacute;s&aacute;val feljogos&iacute;tja a Felhaszn&aacute;l&oacute;t az Alkalmaz&aacute;s nem kiz&aacute;r&oacute;lagos, korl&aacute;tozott haszn&aacute;lat&aacute;ra.</p>

		<p>A Felhaszn&aacute;l&oacute; a haszn&aacute;latra kiz&aacute;r&oacute;lag abban a m&eacute;rt&eacute;kben jogosult, amely minim&aacute;lisan elegendő a jelen &Aacute;SZF-ből eredő jogainak &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;hez &eacute;s k&ouml;telezetts&eacute;geinek teljes&iacute;t&eacute;s&eacute;hez.</p>

		<p>Jogtalan programhaszn&aacute;lat eset&eacute;n a Szolg&aacute;ltat&oacute;nak k&aacute;rt&eacute;r&iacute;t&eacute;si ig&eacute;nye keletkezik, &eacute;s jogosultt&aacute; v&aacute;lik a szerzői jogr&oacute;l sz&oacute;l&oacute; 1999. &eacute;vi LXXVI. t&ouml;rv&eacute;nyben meghat&aacute;rozott polg&aacute;ri jogi, valamint a b&uuml;ntetőjogi k&ouml;vetkezm&eacute;nyek &eacute;rv&eacute;nyes&iacute;t&eacute;se ir&aacute;nt a megfelelő elj&aacute;r&aacute;sok megind&iacute;t&aacute;s&aacute;ra.</p>

		<h2>4. Szolg&aacute;ltat&oacute; k&ouml;teless&eacute;gei</h2>

		<p>A Szolg&aacute;ltat&oacute; nem felel azok&eacute;rt a k&aacute;rok&eacute;rt, amelyek abb&oacute;l fakadnak, hogy a Felhaszn&aacute;l&oacute; nem rendeltet&eacute;sszerűen haszn&aacute;lja az Alkalmaz&aacute;st, a Szolg&aacute;ltat&aacute;st, vagy az ezek ig&eacute;nybev&eacute;tel&eacute;hez sz&uuml;ks&eacute;ges eszk&ouml;z&ouml;ket, illetve nem kellő gondoss&aacute;ggal v&aacute;lasztja ki a megfelelő biztons&aacute;gos k&ouml;rnyezetet, amelyben a Szolg&aacute;ltat&aacute;sokat ig&eacute;nybe veszi. A Felhaszn&aacute;l&oacute; sz&aacute;m&iacute;t&aacute;stechnikai rendszer&eacute;ben (ide&eacute;rtve b&aacute;rmilyen okostelefont is) t&ouml;rt&eacute;nő v&iacute;rus- &eacute;s egy&eacute;b hasonl&oacute; fertőz&eacute;sekből eredő k&aacute;rok&eacute;rt a Szolg&aacute;ltat&oacute; nem tartozik felelőss&eacute;ggel.</p>

		<p>A Szolg&aacute;ltat&oacute; nem felelős az olyan k&aacute;rok&eacute;rt, amely a Szolg&aacute;ltat&oacute; &aacute;ltal el nem h&aacute;r&iacute;that&oacute; ok, vagy nem a Szolg&aacute;ltat&oacute; &eacute;rdekk&ouml;r&eacute;be tartoz&oacute; ok miatt k&ouml;vetkezik be, &iacute;gy k&uuml;l&ouml;n&ouml;sen a t&aacute;vk&ouml;zl&eacute;si vonalak, az internetes kapcsolat, az adat&aacute;tviteli rendszerek vagy a harmadik f&eacute;l &aacute;ltal biztos&iacute;tott szolg&aacute;ltat&aacute;sok hib&aacute;i&eacute;rt.</p>

		<p>A Szolg&aacute;ltat&oacute; nem felelős az olyan k&aacute;rok&eacute;rt, amelyek olyan ok miatt k&ouml;vetkeznek be, amelynek az elh&aacute;r&iacute;t&aacute;s&aacute;ra, vagy befoly&aacute;sol&aacute;s&aacute;ra a Szolg&aacute;ltat&oacute;nak nincs lehetős&eacute;ge, &iacute;gy k&uuml;l&ouml;n&ouml;sen az erőhatalomb&oacute;l (vis maior), belf&ouml;ldi vagy k&uuml;lf&ouml;ldi hat&oacute;s&aacute;g rendelkez&eacute;s&eacute;ből, vagy a Szolg&aacute;ltat&oacute; műk&ouml;d&eacute;s&eacute;nek megzavar&aacute;s&aacute;b&oacute;l eredő k&aacute;rok&eacute;rt, &iacute;gy abban az esetben sem felel a Szolg&aacute;ltat&oacute; az ilyen ok miatt bek&ouml;vetkező k&aacute;rok&eacute;rt, amennyiben ezek miatt a Szolg&aacute;ltat&oacute; ideiglenesen besz&uuml;nteti vagy korl&aacute;tozza az Alkalmaz&aacute;st, a Szolg&aacute;ltat&aacute;st, vagy a Szolg&aacute;ltat&aacute;s el&eacute;r&eacute;s&eacute;t, illetőleg ny&uacute;jt&aacute;s&aacute;t.</p>

		<p>A Szolg&aacute;ltat&oacute; nem felelős az olyan esem&eacute;nyek&eacute;rt, k&ouml;r&uuml;lm&eacute;nyek&eacute;rt, k&aacute;rok&eacute;rt &eacute;s egy&eacute;b k&ouml;vetkezm&eacute;nyek&eacute;rt, amelynek oka:</p>

		<ul>
			<li>a Felhaszn&aacute;l&oacute; hib&aacute;ja;</li>
			<li>a Felhaszn&aacute;l&oacute; mobileszk&ouml;z&eacute;nek meghib&aacute;sod&aacute;sa vagy az Alkalmaz&aacute;s kezel&eacute;s&eacute;re val&oacute; alkalmatlans&aacute;ga;</li>
			<li>a Felhaszn&aacute;l&oacute; mobileszk&ouml;z&eacute;n l&eacute;vő oper&aacute;ci&oacute;s rendszer, illetőleg a Felhaszn&aacute;l&oacute; &aacute;ltal ig&eacute;nybe vett kommunik&aacute;ci&oacute;s kapcsolat hib&aacute;ja;</li>
			<li>az Alkalmaz&aacute;s rendeltet&eacute;sellenes vagy egy&eacute;bk&eacute;nt a jelen &Aacute;SZF-be &uuml;tk&ouml;ző haszn&aacute;lata;</li>
			<li>az Alkalmaz&aacute;s &aacute;ltal bizony&iacute;that&oacute;an kik&uuml;ld&ouml;tt automatikus (t&aacute;j&eacute;koztat&oacute; c&eacute;l&uacute;) rendszer&uuml;zenetek Felhaszn&aacute;l&oacute; levelezőrendszer&eacute;be t&ouml;rt&eacute;nő meg nem &eacute;rkez&eacute;se.</li>
		</ul>

		<p>A Felhaszn&aacute;l&oacute; jelszav&aacute;nak biztons&aacute;gos t&aacute;rol&aacute;s&aacute;&eacute;rt, illetve az ebből eredő k&aacute;rok&eacute;rt Szolg&aacute;ltat&oacute; semmilyen felelőss&eacute;get nem v&aacute;llal.</p>

		<p>A Szolg&aacute;ltat&oacute; a fentieken fel&uuml;l az Alkalmaz&aacute;s pontoss&aacute;g&aacute;&eacute;rt, megb&iacute;zhat&oacute;s&aacute;g&aacute;&eacute;rt, műk&ouml;d&eacute;s&eacute;&eacute;rt, teljess&eacute;g&eacute;&eacute;rt, adott c&eacute;lra vagy elv&aacute;r&aacute;soknak val&oacute; megfelelős&eacute;g&eacute;&eacute;rt, alkalmass&aacute;g&aacute;&eacute;rt &eacute;s az ezek hi&aacute;ny&aacute;b&oacute;l, az Alkalmaz&aacute;s haszn&aacute;lat&aacute;b&oacute;l felmer&uuml;lő &eacute;s azokb&oacute;l eredő k&ouml;zvetlen vagy k&ouml;zvetett k&aacute;rok&eacute;rt vagy egy&eacute;b jogk&ouml;vetkezm&eacute;nyek&eacute;rt egy&eacute;b szavatoss&aacute;got vagy m&aacute;s k&ouml;telezetts&eacute;get nem v&aacute;llal. A Szolg&aacute;ltat&oacute; nem felel tov&aacute;bb&aacute; a rajta k&iacute;v&uuml;l &aacute;ll&oacute; okkal (p&eacute;ld&aacute;ul az internetes h&aacute;l&oacute;zatban keletkezett technikai meghib&aacute;sod&aacute;ssal vagy &aacute;ramkimarad&aacute;ssal) &ouml;sszef&uuml;gg&eacute;sben keletkezett hib&aacute;k&eacute;rt &eacute;s ezek k&ouml;vetkezm&eacute;nyei&eacute;rt.</p>

		<p>A jelen &Aacute;SZF elfogad&aacute;s&aacute;val a felek a Ptk. 6:152. &sect;-ban foglaltakra tekintettel meg&aacute;llapodnak, hogy a - sz&aacute;nd&eacute;kosan, tov&aacute;bb&aacute; az emberi &eacute;letben, testi &eacute;ps&eacute;gben, eg&eacute;szs&eacute;gben okozott k&aacute;rok kiv&eacute;tel&eacute;vel - Szolg&aacute;ltat&oacute; a Weboldal, valamint az Alkalmaz&aacute;s haszn&aacute;lata kapcs&aacute;n a Felhaszn&aacute;l&oacute;nak okozott k&aacute;rok&eacute;rt legfeljebb az esem&eacute;nnyel &eacute;rintett Szolg&aacute;ltat&aacute;s ellen&eacute;rt&eacute;k&eacute;nek &ouml;sszege erej&eacute;ig felel. Felek meg&aacute;llapodnak tov&aacute;bb&aacute;, hogy Szolg&aacute;ltat&oacute; felelőss&eacute;g&eacute;t kiz&aacute;r&oacute;lag a Szerződ&eacute;sben meghat&aacute;rozott tev&eacute;kenys&eacute;ggel k&ouml;zvetlen &ouml;sszef&uuml;gg&eacute;sben keletkezett k&aacute;rokra korl&aacute;tozz&aacute;k, &iacute;gy a k&ouml;zvetett, előre nem l&aacute;that&oacute;, k&ouml;vetkezm&eacute;nyes k&aacute;rokra, valamint harmadik f&eacute;l &aacute;ltal szolg&aacute;ltatott szoftverek haszn&aacute;lat&aacute;val &ouml;sszef&uuml;gg&eacute;sben keletkező k&aacute;rokra nem terjed ki.</p>

		<h2>5. Pilotnet Pro</h2>

		<p><b>PilotNet Pro</b>, a kifejezés azokat a funkciókat jelöli, amelyek kiterjesztik a normál PilotNet rendszer szolgáltatásait (mint például elektronikus repülés napló), azonban ezen szolgáltatások az alapszolgáltatásokkal ellentétben nem ingyenesek.</p>

		<h3>a) Előfizetés, feliratkozás</h3>

		<h4>Ingyenes próbaidőszak</h4>

		<ul>
			<li>A PilotNet Pro tagság ingyenes próbaidőszakkal indul. Az ingyenes próbaidőszak célja, hogy az új és adott korábbi felhasználók kipróbálhassák a szolgáltatást.</li>
			<li>A Szolgáltató saját belátása szerint határozza meg az ingyenes próba jogosultságát, és korlátozhatja azt a visszaélések elkerülése érdekében. Fenntartjuk a jogot arra, hogy visszavonjuk az ingyenes próbát, illetve zároljuk az adott fiókot, ha úgy ítéljük meg, hogy a felhasználó nem jogosult rá.</li>
			<li>Azok a próbaidőszakos fiókok, amelyekhez nem tartozik aktív előfizetés, törölhetők a próbaidőszak lejárta után.</li>
			<li>Jogosultság meghatározásához felhasználhatunk például eszközazonosítót vagy olyan fiók e-mail-címét, amelyet korábbi vagy meglévő tagsággal használtak. Egyéb ajánlatokkal való kombinálás esetén korlátozások érvényesek lehetnek.</li>
		</ul>

		<h4>Előfizetés</h4>

		<ul>
			<li>Az előfizetések lehetnek automatikusan megújuló típusúak, vagy egyszeri fizetésen alapulóak.</li>
			<li>Az induló és ismétlődő díjak összegét a PilotNet Pro weboldala feltünteti az előfizetés megkötésekor. Az ismétlődő díjak összege a jövőben változhat, amelyről legalább 30 nappal korábban értesítést küldünk. Az előfizetési díj változásáról az Ön fiókjában szereplő e-mail-címre küldünk értesítést.</li>
			<li>Az előfizetések bármikor lemondhatók. Az előfizetés a jelenlegi számlázási időszak végéig érvényben marad. Az aktuális vagy fel nem használt számlázási időszakra vonatkozó visszatérítést nem biztosítunk. Az előfizetés lemondása a felhasználói fiók törlésével történhet meg.</li>
			<li>A megújuló előfizetés esetén, az automatikus megújítása az előfizetésnek a “Profil” menüpont alatt kikapcsolható</li>
		</ul>

		<h3>b) A felhasználó kötelezettségei</h3>

		<ul>
			<li>Tilos a rendszerben nem létező repüléseket (valódi repülőgépek vagy pilótaképzéshez használt repülőszimulátorok nélküli repüléseket) menteni vagy exportálni.</li>
			<li>A felhasználók kötelesek rendszeresen biztonsági mentést készíteni fontos adataikról, információikról és tartalmaikról, beleértve a jegyzeteket, beállításokat és preferenciákat a Szolgáltatásban. Az adatvesztés megelőzése érdekében a Szolgáltató erősen javasolja a rendszeres biztonsági mentések elvégzését. Kérjük, vegye figyelembe, hogy a Szolgáltatónak nincs kötelezettsége az adatok archiválására vagy mentésére.</li>
			<li>Az Alkalmazásban található információk felhasználása saját felelősségre történik. A Szolgáltató nem tehető felelőssé semmilyen közvetlen vagy közvetett kárért és/vagy életveszteségért, amelyet az Alkalmazásban tárolt vagy exportált információk használata okoz.</li>
		</ul>

		<h2>6. Felmond&aacute;s a Felhaszn&aacute;l&oacute; r&eacute;sz&eacute;ről</h2>

		<p>A jelen &Aacute;SZF Felhaszn&aacute;l&oacute; &aacute;ltal b&aacute;rmikor, azonnali hat&aacute;llyal felmondhat&oacute; a regisztr&aacute;ci&oacute; t&ouml;rl&eacute;s&eacute;vel egyidejűleg.</p>

		<p>A regisztr&aacute;ci&oacute; t&ouml;rl&eacute;s&eacute;vel egyidejűleg a Felhaszn&aacute;l&oacute; Alkalmaz&aacute;s profilja automatikusan t&ouml;rl&eacute;sre ker&uuml;l. A m&aacute;r teljes&uuml;lt rep&uuml;l&eacute;sek adatait a Szolg&aacute;ltat&oacute; az &aacute;ltal&aacute;nos el&eacute;v&uuml;l&eacute;si idő letelt&eacute;ig jogosult t&aacute;rolni.</p>

		<h2>7. Felmond&aacute;s a Szolg&aacute;ltat&oacute; r&eacute;sz&eacute;ről</h2>

		<p>A Szolg&aacute;ltat&oacute; jogosult indokol&aacute;si k&ouml;telezetts&eacute;g mellett azonnali hat&aacute;llyal felmondani a jelen meg&aacute;llapod&aacute;st, ha a Felhaszn&aacute;l&oacute; a jelen meg&aacute;llapod&aacute;sba &uuml;tk&ouml;ző m&oacute;don haszn&aacute;lja a Szolg&aacute;ltat&aacute;st. A Szolg&aacute;ltat&oacute; akkor is felmondhatja a jelen meg&aacute;llapod&aacute;st, ha a Szolg&aacute;ltat&oacute; alapos okkal gyan&iacute;tja, hogy a Felhaszn&aacute;l&oacute; nem megfelelően haszn&aacute;lja a Szolg&aacute;ltat&aacute;st.</p>

		<p>A Szolg&aacute;ltat&oacute; rendes felmond&aacute;ssal, egy (1) h&oacute;napos felmond&aacute;si idővel felmondhatja a jelen meg&aacute;llapod&aacute;st, amely a fenn&aacute;ll&oacute; előfizet&eacute;s lej&aacute;rtakor l&eacute;p hat&aacute;lyba (amennyiben alkalmazand&oacute;).</p>

		<h2>8. Szem&eacute;lyes adatok kezel&eacute;se, biztons&aacute;g</h2>

		<p>A Szolg&aacute;ltat&oacute; jogosult a Felhaszn&aacute;l&oacute; &aacute;ltal a Weblapon vagy az Alkalmaz&aacute;sban t&ouml;rt&eacute;nő regisztr&aacute;ci&oacute;, vagy azt k&ouml;vetően b&aacute;rmikor t&ouml;rt&eacute;nt m&oacute;dos&iacute;t&aacute;s sor&aacute;n megadott szem&eacute;lyes adatokat t&aacute;rolni, feldolgozni, &eacute;s a Szolg&aacute;ltat&aacute;ssal kapcsolatban felhaszn&aacute;lni a Szolg&aacute;ltat&oacute; Adatkezel&eacute;si t&aacute;j&eacute;koztat&oacute;j&aacute;ban meghat&aacute;rozottak szerint. A Felhaszn&aacute;l&oacute; k&ouml;teles minden tőle elv&aacute;rhat&oacute;t megtenni szem&eacute;lyes adatainak, k&uuml;l&ouml;n&ouml;sen a Szolg&aacute;ltat&oacute; &aacute;ltal ny&uacute;jtott szolg&aacute;ltat&aacute;sok ig&eacute;nybev&eacute;tel&eacute;hez a Felhaszn&aacute;l&oacute;n&eacute;v, e-mail c&iacute;m, illetve mindk&eacute;t online fel&uuml;let eset&eacute;n a jelsz&oacute; v&eacute;delme &eacute;rdek&eacute;ben. A Felhaszn&aacute;l&oacute; tartozik felelőss&eacute;ggel minden olyan esem&eacute;ny&eacute;rt, tev&eacute;kenys&eacute;g&eacute;rt, mulaszt&aacute;s&eacute;rt, mely Felhaszn&aacute;l&oacute;nev&eacute;nek, illetve jelszav&aacute;nak felhaszn&aacute;l&aacute;s&aacute;val val&oacute;sult meg.</p>

		<h2>9. Egy&eacute;b rendelkez&eacute;sek</h2>

		<p>A jelen szerződ&eacute;s a Szolg&aacute;ltat&oacute; &eacute;s a Felhaszn&aacute;l&oacute; k&ouml;z&ouml;tt l&eacute;trej&ouml;tt, &iacute;r&aacute;sba foglalt szerződ&eacute;snek minős&uuml;l. A Szolg&aacute;ltat&oacute; r&ouml;gz&iacute;ti a jelen szerződ&eacute;st, a jelen szerződ&eacute;s &eacute;s esetleges m&oacute;dos&iacute;t&aacute;sai a k&eacute;sőbbiekben is hozz&aacute;f&eacute;rhetőek a Szolg&aacute;ltat&oacute; honlapj&aacute;n. A jelen szerződ&eacute;s hivatalos nyelve a magyar. Jelen &Aacute;SZF-et Szolg&aacute;ltat&oacute; jogosult egyoldal&uacute;an m&oacute;dos&iacute;tani. A m&oacute;dos&iacute;t&aacute;st a Szolg&aacute;ltat&oacute; k&ouml;teles annak hat&aacute;lyba l&eacute;p&eacute;s&eacute;t megelőző 15 nappal a honlapj&aacute;n k&ouml;zz&eacute;tenni. Az &Aacute;SZF mindenkor hat&aacute;lyos verzi&oacute;j&aacute;t a Szolg&aacute;ltat&oacute; honlapj&aacute;n teszi k&ouml;zz&eacute;.</p>

		<p>14.2. A jelen &Aacute;SZF b&aacute;rmely rendelkez&eacute;s&eacute;nek &eacute;rv&eacute;nytelens&eacute;ge nem &eacute;rinti az egy&eacute;b rendelkez&eacute;seinek &eacute;rv&eacute;nyess&eacute;g&eacute;t &eacute;s hat&aacute;ly&aacute;t, a r&eacute;szleges &eacute;rv&eacute;nytelens&eacute;ge eset&eacute;n a szerződ&eacute;s t&ouml;bbi r&eacute;sze v&aacute;ltozatlan form&aacute;ban hat&aacute;lyban marad.</p>

		<p>14.3. A jelen &Aacute;SZF-ben nem szab&aacute;lyozott k&eacute;rd&eacute;sekben Magyarorsz&aacute;g jogszab&aacute;lyai, k&uuml;l&ouml;n&ouml;sen a polg&aacute;ri t&ouml;rv&eacute;nyk&ouml;nyvről sz&oacute;l&oacute; 2013. &eacute;vi V. t&ouml;rv&eacute;ny, az inform&aacute;ci&oacute;s &ouml;nrendelkez&eacute;si jogr&oacute;l &eacute;s az inform&aacute;ci&oacute;szabads&aacute;gr&oacute;l sz&oacute;l&oacute; 2011. &eacute;vi CXII. t&ouml;rv&eacute;ny, valamint a l&eacute;gik&ouml;zleked&eacute;sre vonatkoz&oacute; hat&aacute;lyos jogszab&aacute;lyok az ir&aacute;nyad&oacute;ak.</p>

	</div>
</template>

<script>
export default {
	name: "GtcView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	},
	methods: {
		scrollTo(refName) {
			let element = this.$refs[refName];
			let top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
